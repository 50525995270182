import { Col, Container, Row } from "reactstrap";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import serveurs from "./config/serveurs";
import { PROD, TEST, DEV } from "./config/env";
import ServerCard from "./components/ServerCard";
import Footer from "./components/Footer";

export default function App() {
  const renderServersEnv = (environment) => {
    return serveurs[environment].map((serveur, key) => (
      <Col
        key={key}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <ServerCard serveur={serveur} />
      </Col>
    ));
  };

  const renderServers = () => {
    return [DEV, TEST, PROD].map((env, key) => (
      <Row key={key}>{renderServersEnv(env)}</Row>
    ));
  };

  return (
    <div className="App" style={styles.color}>
      <Header />
      <Container fluid style={styles.container}>
        {renderServers()}
      </Container>
      <Footer />
    </div>
  );
}

const styles = {
  container: {
    marginTop: 30,
    marginBottom: 25,
    maxWidth: 2700,
    paddingBottom: 65,
    minHeight: 1500,
  },
  color: {
    background:
      "linear-gradient(45deg, rgba(35,47,52,1) 0%, rgba(48,63,69,1) 22%, rgba(35,47,52,1) 100%)",
    backgroundColor: "#232F34",
    color: "#FFFFFF",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};
