import { Col, Navbar, Row } from "reactstrap";
import logo from "../assets/img/logo-crop-white.png";

export default function Header(props) {
  return (
    <header>
      <Navbar
        className="shadow"
        expand="lg"
        container="fixed"
        style={{
          background:
            "linear-gradient(23deg, rgba(221,133,0,1) 30%, rgba(249,170,51,1) 79%)",
          backgroundColor: "#F9AA33",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          height: 60,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {" "}
            <img
              src={logo}
              alt=""
              style={{ height: "3em", width: "6em" }}
            ></img>
          </Col>
        </Row>
      </Navbar>
    </header>
  );
}
