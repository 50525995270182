// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  width: 60px;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loader {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-top-color: #4bc8eb;
  border-bottom-color: #f9aa33;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.loader-inner {
  border-top-color: #4bc8eb;
  border-bottom-color: #f9aa33;
  animation-duration: 2.5s;
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/SpinLoadingAnimation.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;EACE;IACE,kCAAkC;EACpC;EACA;IACE,qCAAqC;EACvC;EACA;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".loader-wrapper {\n  width: 60px;\n  height: 60px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n.loader {\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  border: 5px solid transparent;\n  border-top-color: #4bc8eb;\n  border-bottom-color: #f9aa33;\n  border-radius: 50%;\n  animation: rotate 5s linear infinite;\n}\n\n.loader-inner {\n  border-top-color: #4bc8eb;\n  border-bottom-color: #f9aa33;\n  animation-duration: 2.5s;\n}\n\n@keyframes rotate {\n  0% {\n    transform: scale(1) rotate(360deg);\n  }\n  50% {\n    transform: scale(0.8) rotate(-360deg);\n  }\n  100% {\n    transform: scale(1) rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
