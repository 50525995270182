import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import { CopyBlock, nord } from "react-code-blocks";
import axios from "axios";
import SpinLoadingAnimation from "./SpinLoadingAnimation";
import useConstructor from "../config/hooks/useConstructor";
import { RenderIf } from "../config/utils";

export default function ServerCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const environment = {
    dev: "Développement",
    test: "Test",
    prod: "Production",
  };

  const colorEnvironment = {
    dev: "#ff0000",
    test: "#ffc200",
    prod: "#ff0000",
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(props.serveur.apiUrl + "/status/");

      if (res.status === 200) {
        setStatus(res.data);
      }
      setLoading(false);
      setError(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useConstructor(loadData);

  const renderModal = () => {
    return (
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(false);
        }}
        autoFocus={false}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(false);
          }}
          className="modal-header"
        >
          {props.serveur.name} | {environment[props.serveur.environment]}
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row>
            <Col>
              {" "}
              <h5>Serveur</h5>
              <CopyBlock
                customStyle={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                }}
                text={props.serveur.serverUtl}
                language={"text"}
                theme={nord}
                wrapLines
              />
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h3">{props.serveur.name}</CardTitle>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen}
                target="btn-info"
                toggle={() => setTooltipOpen(!tooltipOpen)}
              >
                Plus d'infos
              </Tooltip>
              <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen}
                target="btn-reload"
                toggle={() => setTooltipOpen(!tooltipOpen)}
              >
                Recharger les informations
              </Tooltip>
              <Button
                style={{ background: "transparent", border: 0, fontSize: 20 }}
                onClick={() => setShowModal(true)}
                id="btn-info"
              >
                <i className="fa-solid fa-circle-info" />
              </Button>
              <Button
                style={{ background: "transparent", border: 0, fontSize: 20 }}
                onClick={async () => await loadData()}
                id="btn-reload"
              >
                <i class="fa-solid fa-rotate" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ marginBottom: 10 }}>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading && !error && status != null}>
            <Row>
              <Col>
                <Table style={{ color: "#FFFFFF" }}>
                  <tbody>
                    <tr>
                      <th scope="row">Version</th>
                      <td>{status?.version}</td>
                    </tr>
                    <tr>
                      <th scope="row">Jasper</th>
                      <td>
                        {status?.jasperStatus === 200
                          ? "Opérationnel"
                          : "Erreur..."}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Web</th>
                      <td>
                        {status?.webStatus === 200
                          ? "Opérationnel"
                          : "Erreur..."}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Base de données</th>
                      <td>
                        Base{" "}
                        {status?.bdhostaccessible
                          ? "accessible"
                          : "inaccessible"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Dernier patch</th>
                      <td>
                        {status?.dernierpatch} - {status?.datedernierpatch}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Environnement</th>
                      <td>
                        <b
                          style={{
                            color: colorEnvironment[props.serveur?.environment],
                          }}
                        >
                          {environment[status?.environment]}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <RenderIf isTrue={status?.usage != null}>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <h2>Utilisation</h2>
                  {status?.usage}
                </Col>
              </Row>
            </RenderIf>
          </RenderIf>
          <RenderIf isTrue={error}>
            ERREUR : route de status non accessible sur ce serveur
          </RenderIf>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            target="_blank"
            href={props.serveur.webUrl}
          >
            S'y rendre
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            target="_blank"
            href={props.serveur.apiUrl + "/docs"}
          >
            API
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            target="_blank"
            href={props.serveur.jasperUrl + "/jasperserver/"}
          >
            Jasper
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}
