import { PROD, TEST, DEV } from "./env";

export default {
  [PROD]: [
    {
      name: "Production",
      environment: "prod",
      code: "prod",
      webUrl: "https://ecodim.apgmb.fr",
      apiUrl: "https://api.ecodim.apgmb.fr",
      jasperUrl: "https://jasper.apgmb.fr",
      serverUtl: "ecodim.apgmb.fr",
    },
  ],
  [TEST]: [
    {
      name: "Test 1",
      environment: "test",
      code: "test1",
      webUrl: "https://ecodim.test.billonprestations.fr",
      apiUrl: "https://api.ecodim.test.billonprestations.fr",
      jasperUrl: "https://jasper.test.billonprestations.fr",
      serverUtl: "bp3-dev.billonprestations.fr",
    },
    {
      name: "Test 2",
      environment: "test",
      code: "test2",
      webUrl: "https://ecodim.test2.billonprestations.fr",
      apiUrl: "https://api.ecodim.test2.billonprestations.fr",
      jasperUrl: "https://jasper.test2.billonprestations.fr",
      serverUtl: "bp-test2.billonprestations.fr",
    },
  ],
  [DEV]: [
    {
      name: "BP2 - dev",
      environment: "dev",
      code: "dev2",
      webUrl: "https://ecodim.bp2.billonprestations.fr",
      apiUrl: "https://api.ecodim.billonprestations.fr",
      jasperUrl: "https://jasper.bp2.billonprestations.fr",
      serverUtl: "bp2-dev.billonprestations.fr",
    },
  ],
};
