import { Container, Navbar } from "reactstrap";

function Footer() {
  return (
    <footer
      style={{
        boxShadow: "0 .5rem 2rem rgba(0,0,0,.5)",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        marginBottom: 0,
        backgroundColor: "#344955",
        height: 50,
      }}
    >
      <Navbar
        className="shadow"
        expand="lg"
        style={{
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          alignContent: "center",
          justifyContent: "space-around",
          display: "flex",
          height: 50,
        }}
      >
        <Container
          style={{
            maxWidth: 1900,
            alignContent: "center",
            justifyContent: "space-around",
            display: "flex",
          }}
        ></Container>
      </Navbar>
    </footer>
  );
}

const styles = {
  container: {
    backgroundColor: "#344955",
  },
  color: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
};

export default Footer;
